import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ImageModule from '~components/ImageModule'
import { mobile, tablet } from '~styles/global'

const serializers = {
	marks: {
		internalLink: props => {
			return <Link to={resolveLink(props.mark.page)}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return (
					<Link 
						to={resolveLink(props.mark.document)} 
						css={css`
							text-decoration: underline; 
							text-decoration-thickness: 0.06em; 
							transition: color 0.2s ease, text-decoration-color 0.2s ease;
							&:hover{
								color: var(--pink);
								text-decoration-color: var(--pink);
							}
						`}>
						{props.children}
					</Link> )
			} else {
				return (
					<a 
						href={props.mark?.url} 
						css={css`
							text-decoration: underline; 
							text-decoration-thickness: 0.06em; 
							transition: color 0.2s ease, text-decoration-color 0.2s ease;
							&:hover{
								color: var(--pink);
								text-decoration-color: var(--pink);
							}
						`} 
						target={props.mark.blank ? '_blank' : '_self'} 
						rel='noreferrer'>
						{props.children}
					</a>)
			}
		},
	},
	types: {
		block: (props) => {
			if (props.children[0] === '') {
				return <Row><br/></Row>
			}
			if (props.node.style === 'h1') {
				return <Row><h1>{props.children}</h1></Row>
			}
			if (props.node.style === 'h2') {
				return <Row><h2 className='sans'>{props.children}</h2></Row>
			}
			if (props.node.style === 'normal') {
				return <Row><p>{props.children}</p></Row>
			}
			return BlockContent.defaultSerializers.types.block(props)
		},
		imageModule: ({node}) => {
			return (
				<>
					<ImageModule module={node}/>
				</>
			)
		},
		button: ({node}) => {
			return (
				<Row>
					<Link 
						to={resolveLink(node.link)} 
						css={css`
							text-decoration: underline; 
							text-decoration-thickness: 0.06em; 
							transition: color 0.2s ease, text-decoration-color 0.2s ease;
							&:hover{
								color: var(--pink);
								text-decoration-color: var(--pink);
							}
						`}>
						{node?.text}
					</Link> 
				</Row>
			)
		},
		quote: ({node}) => {
			return (
				<Row>
					<Quote className='sans'><BlockContent blocks={node?.quote}/></Quote>
				</Row>
			)
		}
	}
}


const Row = styled.div`
	margin-bottom: 20px;
	grid-column: 3/11;
	${tablet}{
		grid-column: 2/12;
	}
	${mobile}{
		grid-column: 1/13;
	}
`

const Quote = styled.h2`
	max-width: 80%;
	${tablet}{
		max-width: 100%;
	}
`
const RichText = ({ content }) => <StyledBlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>

const StyledBlockContent = styled(BlockContent)`
	display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
	padding: 0 110px;
	${tablet}{
		padding: 0 40px;
	}
	${mobile}{
		padding: 0 20px;
		grid-column-gap: 20px;
	}

`
RichText.propTypes = {
	content: PropTypes.array,
	pinkLinks: PropTypes.bool
}

export default RichText
