import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import ImageWithCaption from '~components/ImageWithCaption'

const ImageModule = ({ className, module }) => {
	return (
		<Wrap className={className} fullWidth={module.width === 'fullWidth'}>
			<ImageWithCaption image={module.image}/>
		</Wrap>
	)
}

const Wrap = styled.div`
	grid-column: ${props => props.fullWidth ? 'span 12' : '3/11'};
	margin: ${props => props.fullWidth ? '0 -80px 20px' : '0 0 20px'};
	${tablet}{
		margin: ${props => props.fullWidth ? '0 -40px 20px' : '0 0 20px'};
		grid-column: ${props => props.fullWidth ? 'span 12' : '2/12'};
	}
	${mobile}{
		margin: 0 -20px 20px;
		grid-column: span 12;
	}
`

ImageModule.propTypes = {
	className: PropTypes.string,
	module: PropTypes.object,
}

export default ImageModule