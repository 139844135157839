import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Image from '~components/Image'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import { parseISO, format } from 'date-fns'
import { mobile, tablet } from '~styles/global'
import ArticleText from '~components/ArticleText'

const Page = ({ data }) => {
	const { page } = data

	const [date, setDate] = useState()

	useEffect(() => {		
		console.log('publishedAt', page?.publishedAt)
		if (!page?.publishedAt) return
		const dateString = page?.publishedAt
		const date = parseISO(dateString)
		const formattedDate = format(date, 'dd.MM.yy')
		setDate(formattedDate)
	}, [page?.publishedAt])
	
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Header>
				<HeaderImage image={page?.mainImage} cover/>
				<HeaderContent>
					<ArticleSection>
						<ArticleRow>
							<Date className='sans-sm'>{date}</Date>
							<Title className='sans'>{page?.title}</Title>
							<ShortText className=''>
								<RichText content={page?.shortText}/>
							</ShortText>
						</ArticleRow>
					</ArticleSection>
				</HeaderContent>
				<Gradient />
			</Header>
			<Content>
				<ArticleText content={page.text}/>
			</Content>
			<DataViewer data={page} name="content"/>
		</>
	)
}

Page.propTypes = {
	data: PropTypes.object,
}

const Date = styled.div`
	margin-bottom: 4px;
	${mobile}{
		margin-bottom: 6px;
	}
`
const Title = styled.h2`
	margin-bottom: 6px;
	${mobile}{
		max-width: 70%;
	}
`
const ShortText = styled.div`
	margin-bottom: 30px;	
`
const ArticleSection = styled(Section)`
	>div{
		padding: 0 110px;
	}
	${tablet}{
		>div {
			padding: 0 40px;
		}
	}
	${mobile}{
		>div {
			padding: 0 20px;
		}
	}
`
const Gradient = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 14	0px;
	background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
`
const ArticleRow = styled.div`
	grid-column: 3/11;
	${tablet}{
		grid-column: 2/12;
	}
	${mobile}{
		grid-column: 1/13;
	}
`
const HeaderContent = styled.div`
	position: absolute;
	z-index: 10;
	bottom: 0;
	left: 0;
	color: white;
	width: 100%;
`
const Header = styled.div`
	height: 0;
	padding-bottom: 45%;
	position: relative;
	${mobile}{
		padding-bottom: 0;
		height: 100vh;	
	}
`
const HeaderImage = styled(Image)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`
const Content = styled.div`
	padding-top: 40px;
	background-color: #fff;
	padding-bottom: 200px;
	${mobile}{
		padding-top: 30px;
		padding-bottom: 70px;
	}
`
export const query = graphql`
  query articleQuery($slug: String) {
    page: sanityArticle(slug: {current: {eq: $slug}}) {
      title
			mainImage{
				...imageWithAlt
			}
			publishedAt
			shortformTitle
			shortText: _rawShortText(resolveReferences: {maxDepth: 5})
			text: _rawContent(resolveReferences: {maxDepth: 5})
			seo{
				...seo
			}
    }
  }
`

export default Page